export type Category = {
  name: string
  label: string
}

export type MapCoordinates = { lng: number; lat: number }

export type Ambassador = {
  id: number
  email: string
  name: string
  profileImage: string
  major: string
  year: string
  bio: string
  hometown: string
  interests: string[]
}

export type Media = {
  id: string
  locationId: string
  description: string
  url: string
}

export type Location = {
  id: string
  name: string
  website?: string
  phone: string
  email?: string
  description?: string
  cordinates: MapCoordinates
  coverImage: string
  categories: Category[]
  businessCategory: Category[]
  onCampus?: boolean
  tags: string[]
  eventTitle: string
  eventDescription: string
}

export type Video = {
  id: number
  title: string
  description: string
  thumbnail: string
  ambassadorId: number
  tourPointId: string
  url: string
}

export type Tour = {
  id: string
  isCustom?: boolean
  description?: string
  title: string
  locations: Location[]
}

export type TourEvent = Tour & { description?: string; featuredimg?: string }

export type MenuItem = {
  id: string
  label: string
  slug: string
  url?: string
  linkName:string,
  parent?: MenuItem
  postId?: string
  childItems?: MenuItem[]
}

/************* CONFIGURATION TYPES **************/
export type ActiveFeatures = {
  content_menu: boolean
  custom_tour: boolean
  off_campus_locations: boolean
  hide_logo: boolean
}

export type ConfigUrls = {
  manifest_url: string
  redirect_url: string
  wordpress_api_url: string
  wordpress_content_url: string
  wordpress_contact_page_url: string
  post_tour_survey_url: string
  pre_tour_form_url: string
}

export type SchoolColors = {
  primary: string
  secondary: string
}

export type SchoolConfig = {
  features: ActiveFeatures
  location: MapCoordinates
  name: string
  sub_domain: string
  urls: ConfigUrls
  colors: SchoolColors
}

type EditorEntry = {
  [key: string]: boolean
}
export type SchoolDocument = {
  id: string
  liveConfig: SchoolConfig
  draftConfig: SchoolConfig
  editors: EditorEntry
}

export function isTourEvent(obj: Tour | TourEvent): obj is TourEvent {
  return !!(obj as TourEvent)?.featuredimg
}