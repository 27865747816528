import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'

export type NavBarProps = {
  shrink?: boolean
}

const styles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    zIndex: theme.zIndex.modal,
    
    backgroundColor: '#FFF',
    color: theme.palette.secondary.main,
    left: 0,
    top: 'auto',
    bottom: 0,
    paddingBottom: `env(safe-area-inset-bottom)`
  },
  selected: {
    backgroundColor: fade(theme.palette.secondary.main, 0.1)
  },
  tabLabel: {
    transitionProperty: 'height, opacity',
    transitionDuration: '0.2s',
    height: (props: NavBarProps) => (props.shrink ? 0 : 22),
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.775rem'
    }
  },
  indicator: {
    height: 5
  },
  root: {
    minHeight: '40px !important',
    transition: 'background-color 0.5s'
  }
}))

export default styles
