import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
type formProps = {
    url?: string,
}

const backBtnStyle = {
    position: 'absolute' as 'absolute',
    top: "20px",
    left: "20px",
    zIndex: 99999,
}
const custModalStyle = {
    position: "fixed" as "fixed",
    zIndex: 9998,
    top: 0,
    left: 0,
    background: "rgba(0, 0, 0, 0.8)",
    height: "100%",
    width: "100%",
    overflowX: "hidden" as "hidden",
    overflowY: "auto" as "auto",
    padding: "32px 16px"
};
const custModalDialogStyle = {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    minHeight: "100%",
};
const modalWarpperStyle = {
    position: "relative" as "relative",
    width: "95%",
    maxWidth: "100%",
    borderRadius: "16px",
    height: "inherit",
}
const DynamicForm: FC<formProps> = ({ url }) => {
    const history = useHistory()
    const handleBack = () => {
        history.push('/menu');
    }

    return (
            <div style={custModalStyle}>
                <div style={custModalDialogStyle}>
                    <div style={modalWarpperStyle}>
                        <span style={backBtnStyle} role='button'
                            tabIndex={0}
                            onClick={handleBack}
                            onKeyDown={(e) => {
                                if (e.key === 'Escape') {
                                    handleBack();
                                }
                            }}

                        >
                            <ArrowBackIos data-test="header-back-icon" />
                        </span>
                        <iframe src={url}
                            title="Thanks For Visiting"
                            style={{
                                width: '100%',
                                height: '100%',
                                border: 0,
                                zIndex: 5000,
                                position: 'relative'
                            }} >
                        </iframe>
                    </div>
                </div>
            </div>

    )
}

export default DynamicForm