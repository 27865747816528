import React, { useEffect } from "react";

// Extend the Window interface to include Google Translate
declare global {
  interface Window {
    google: {
      translate: {
        TranslateElement: new (options: any, elementId: string) => void;
      };
    };
    googleTranslateElementInit: () => void;
  }
}

const GoogleTranslate: React.FC = () => {
  useEffect(() => {
    const addScript = document.createElement("script");
    addScript.src =
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    addScript.async = true;
    document.body.appendChild(addScript);

    // Define the function globally
    window.googleTranslateElementInit = () => {
      if (window.google && window.google.translate) {
        new window.google.translate.TranslateElement(
          { pageLanguage: "en", autoDisplay: false },
          "google_translate_element"
        );
      }
    };

    // setTimeout(() => {
    //   const selectElement = document.querySelector(
    //     ".goog-te-combo"
    //   ) as HTMLSelectElement;
    //   if (selectElement) {
    //     selectElement.value = "en"; // Set English as the default
    //     selectElement.dispatchEvent(new Event("change")); // Trigger language change
    //   }
    // }, 500);

    // Inject CSS to hide the Google Translate toolbar
    const style = document.createElement("style");
    style.innerHTML = `
         body > .skiptranslate {
        display: none;
      }

      .goog-te-banner-frame.skiptranslate {
        display: none !important;
      }
      body {
        top: 0px !important;
      }
      @media print {
        #google_translate_element {
          display: none;
        }
      }
             .skiptranslate.goog-te-gadget {
        max-width: 130px;
        width: 100%;
        margin-right: 0;
        margin-left: auto;
      }
      .goog-te-combo {
            width: 100%;
        }
        @media (max-width: 767px) {
   
      .skiptranslate.goog-te-gadget {
        max-width: 100px;
        width: 100%;
        margin-right: 0;
        margin-left: auto;
      }
      .goog-te-combo {
            width: 100%;
        }
      .skiptranslate.goog-te-gadget span {display: none;}
           }
      .skiptranslate.goog-te-gadget a {display: none;}
     `;
    document.head.appendChild(style);
      const removePoweredByText = () => {
        const poweredBy = document.querySelector(".goog-te-gadget");
        if (poweredBy) console.log(poweredBy);
        if (poweredBy) {
          poweredBy.childNodes.forEach((node) => {
            if (node.nodeType === Node.TEXT_NODE && node.nodeValue && node?.nodeValue.includes("Powered by")) {
              node.nodeValue = ""; // Remove the text content
            }
          });
        }
      };
  
      setTimeout(removePoweredByText, 1000); // Wait for translation widget to load
    
  }, []);

  return <div id="google_translate_element"></div>;
};

export default GoogleTranslate;
