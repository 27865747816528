// @flow
import Typography from '@material-ui/core/Typography'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'src/components/atoms/links/Link'
import useStyles from 'src/components/atoms/links/MenuLink/index.styles'
import DynamicForm from 'src/components/templates/menu/DynamicForm'
type MenuLinkProps = {
  to: string
  variant?: 'main' | 'sub'
  highlight?: boolean
  children: string
  external?: boolean;
  url?: string
}

export const MenuLink = ({
  to,
  variant = 'main',
  highlight,
  children,
  url,
  ...props
}: MenuLinkProps) => {
  const styles = useStyles();
  const [showDynamicForm, setShowDynamicForm] = useState(false);
  const history = useHistory();
  const pathName = history.location.search;
  const handleMenuLinkClick = (e: any) => {
    // if (to.includes('thanks-for-visiting')) {
    //   localStorage.setItem('thanks-for-visitig', url as string);
    //   return;
    // }
    if (to.includes("campusvisitorguides.com")) {
      e.preventDefault(); // Prevent default navigation
      history.push("?external=true");
      setShowDynamicForm(true);
      return
      // <DynamicForm url={to} />
    }
    if (to.includes('thanks-for-visiting')) {
      localStorage.setItem('thanks-for-visitig', url as string);
      return;
    }
  };
  useEffect(() => {
    if (pathName.includes("external=true") && to.includes("campusvisitorguides.com")) {
      setShowDynamicForm(true);
    }
  }, [pathName,to]);
  return (<>
    {
      showDynamicForm ? <DynamicForm url={to} /> :
        <Link {...props} to={to} onClick={handleMenuLinkClick}>
          <Typography
            variant={variant === 'main' ? 'h1' : 'h2'}
            className={`${variant === 'main' ? styles.mainLink : styles.subLink} ${highlight ? styles.mainLinkHighlight : ''
              }`}
          >
            {children}
          </Typography>
        </Link>
    }

  </>)
}

