import React, { FC } from 'react'
import { MenuLink } from 'src/components/atoms/links/MenuLink'
import { Content } from 'src/components/organisms/Content'
import { Header } from 'src/components/organisms/Header'
import useStyles from 'src/components/templates/menu/ContentMenu/index.styles'
import Logo from 'src/images/logos/cu.png'
import { MenuItem } from 'src/utils/types'
import { useContentUrl } from 'src/hooks/useContentUrl'
import { useHistory } from 'react-router-dom'

type ContentMenuTemplateProps = {
  schoolIcon?: any
  universityName: string
  section?: string
  onBack?: () => void
  menuItems?: MenuItem[]
}

export const ContentMenu: FC<ContentMenuTemplateProps> = ({
  schoolIcon = Logo,
  section,
  onBack,
  menuItems,
  universityName
}) => {
  const styles = useStyles()
  const contentUrl = useContentUrl(section)
  const history = useHistory()
  const pathname = history?.location?.pathname || ''

  // Split the path into segments (e.g. "/menu/student-life" => ["menu","student-life"]) 
  const pathSegments = pathname.split('/').filter(seg => seg.trim() !== '')
  // Remove "menu" => activeSlugs is [] if URL is exactly "/menu"
  const activeSlugs = pathSegments[0] === 'menu' ? pathSegments.slice(1) : []
  // 1) If first slug is "http:" or "https:", rebuild & redirect (external URL).
  if (
    activeSlugs.length > 0 &&
    (activeSlugs[0].toLowerCase() === 'http:' ||
      activeSlugs[0].toLowerCase() === 'https:')
  ) {
    const externalUrl = activeSlugs[0] + '//' + activeSlugs.slice(1).join('/')
    window.location.href = externalUrl
    return null
  }

  // 2) Build the active chain (case-insensitive matching).
  const getMenuChain = (items: MenuItem[], slugs: string[]): MenuItem[] => {
    let chain: MenuItem[] = []
    let currentItems = items
    for (const slug of slugs) {
      const found = currentItems.find(
        item => item.slug.toLowerCase() === slug.toLowerCase()
      )
      if (!found) break
      chain.push(found)
      currentItems = found.childItems || []
    }
    return chain
  }

  const activeChain = menuItems ? getMenuChain(menuItems, activeSlugs) : []

  // 3) If we have slugs but no matching chain => fallback to top-level
  if (activeSlugs.length > 0 && activeChain.length === 0) {
    return (
      <>
        <Header
          onBack={onBack}
          title="Main Menu"
          variant="DEFAULT"
          schoolIcon={schoolIcon}
        />
        <Content
          title={universityName}
          description="Invalid path. Please select a valid menu item."
        >
          <div className={styles.contentWrapper}>
            <div className={styles.mainMenu}>
              {menuItems?.map(item => (
                <MenuLink
                  key={item.id}
                  variant="main"
                  to={`/menu/${item.slug}`}
                  url={item.url}
                >
                  {item.label}
                </MenuLink>
              ))}
            </div>
          </div>
        </Content>
      </>
    )
  }

  // 4) Determine if we are at top-level or deeper
  const isTopLevel = activeSlugs.length === 0
  // The last item in the chain (active parent)
  const activeItem = activeChain[activeChain.length - 1]

  // 5) Decide what big heading to show in the <Content>
  const contentTitle = isTopLevel
    ? universityName // e.g. "University of Rhode Island"
    : activeItem?.label || universityName // e.g. "Student Life"

  // 6) Link logic: if item has children => drill down, else => external or direct link
  const getLinkProps = (item: MenuItem) => {
    const hasChildren = item.childItems && item.childItems.length > 0;
    const isExternal =
      item.url &&
      (item.url.startsWith('http://') || item.url.startsWith('https://'))
    if (hasChildren) {
      if (pathSegments.length === 2) {
        return { to: item.url, external: isExternal }
      }
      // Drill-down => /menu/child-slug
      return { to: `/menu/${item.slug}`, external: false }
    } else {
      // External or direct link
      return {
        to: isExternal ? item.url : item.url ? item.url : `${contentUrl}/${item.slug}`,
        external: isExternal
      }
    }
  }

  // 7) Render the parent item + its children (for deeper levels)
  // const renderParentAndChildren = (parent: MenuItem) => {
  //   const parentLinkProps = getLinkProps(parent)
  //   const hasChildren = parent.childItems && parent.childItems.length > 0

  //   return (
  //     <>
  //       {/* The parent itself as first item */}
  //       <MenuLink
  //         key={parent.id}
  //         variant="main"
  //         to={hasChildren ? `/menu/${activeSlugs.join('/')}` : (parentLinkProps.to || "")}
  //         external={parentLinkProps?.external || false}
  //         url={parent.url}
  //       >
  //         {parent.label}
  //       </MenuLink>
  //       {/* Then render the children below */}
  //       {parent.childItems?.map(child => {
  //         const childLinkProps = getLinkProps(child)
  //         const childHasChildren =
  //           child.childItems && child.childItems.length > 0
  //         return (
  //           <MenuLink
  //             key={child.id}
  //             variant="sub"
  //             to={
  //               childHasChildren
  //                 ? `/menu/${[...activeSlugs, child.slug].join('/')}`
  //                 : (childLinkProps.to || '')
  //             }
  //             external={childLinkProps?.external || false}
  //             url={child.url}
  //           >
  //             {child.label}
  //           </MenuLink>
  //         )
  //       })}
  //     </>
  //   )
  // }
  // const renderParentAndChildren = (parent: MenuItem) => {
  //   const parentLinkProps = getLinkProps(parent);
  //   const hasChildren = parent.childItems && parent.childItems.length > 0;

  //   // Check if any child has its own children
  //   const hasGrandChildren = hasChildren && parent && parent.childItems && parent.childItems.some(child =>
  //     Array.isArray(child.childItems) && child.childItems.length > 0
  //   );

  //   return (
  //     <>
  //       {/* The parent itself as first item */}
  //       <MenuLink
  //         key={parent.id}
  //         variant="main"
  //         to={hasGrandChildren ? `/menu/${activeSlugs.join('/')}` : (parentLinkProps.to || "")}
  //         external={parentLinkProps?.external || false}
  //         url={parent.url}
  //       >
  //         {parent.label}
  //       </MenuLink>

  //       {/* If the parent has grandchildren, allow further nesting */}
  //       {hasGrandChildren &&
  //         parent.childItems?.map(child => {
  //           const childLinkProps = getLinkProps(child);
  //           const childHasChildren =
  //             child.childItems && child.childItems.length > 0;
  //           return (
  //             <MenuLink
  //               key={child.id}
  //               variant="sub"
  //               to={
  //                 childHasChildren
  //                   ? `/menu/${[...activeSlugs, child.slug].join('/')}`
  //                   : (childLinkProps.to || '')
  //               }
  //               external={childLinkProps?.external || false}
  //               url={child.url}
  //             >
  //               {child.label}
  //             </MenuLink>
  //           );
  //         })}
  //     </>
  //   );
  // };
  const renderParentAndChildren = (parent: MenuItem) => {
    const parentLinkProps = getLinkProps(parent);
    const hasChildren = parent.childItems && parent.childItems.length > 0;

    return (
      <>
        {/* The parent itself as first item */}
        <MenuLink
          key={parent.id}
          variant="main"
          to={hasChildren ? `/menu/${activeSlugs.join('/')}` : (parentLinkProps.to || "")}
          external={parentLinkProps?.external || false}
          url={parent.url}
        >
          {parent.label}
        </MenuLink>

        {/* Only render direct children, but stop if they have their own children */}
        {hasChildren && parent && parent.childItems &&
          parent.childItems.map(child => {
            const childLinkProps = getLinkProps(child);
            const childHasChildren =
              Array.isArray(child.childItems) && child.childItems.length > 0;

            return (
              <React.Fragment>
                <MenuLink
                  key={child.id}
                  variant="sub"
                  to={childHasChildren ? child.url : (childLinkProps.to || 'test')} // Prevent deeper nesting
                  external={childLinkProps?.external || false}
                  url={child.url}
                >
                  {child.label}
                </MenuLink>

                {/* If this child has children, show them below it */}
                {childHasChildren && (
                  <ul>
                    {child && child.childItems && child.childItems.map(subChild => {
                      const subChildLinkProps = getLinkProps(subChild);
                      return (
                        <li key={subChild.id} className={styles.list}>
                          <MenuLink
                            variant="sub"
                            to={subChildLinkProps.to || ''}
                            external={subChildLinkProps?.external || false}
                            url={subChild.url}
                          >
                            {subChild.label}
                          </MenuLink>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </React.Fragment>
            );
          })}

      </>
    );
  };

  return (
    <>
      {/* Only change: if top-level, show universityName in header; else show "Main Menu" */}
      <Header
        onBack={onBack}
        title={isTopLevel ? universityName : 'Main Menu'}
        variant="DEFAULT"
        schoolIcon={schoolIcon}
      />
      <Content
        title={contentTitle}
        description="Have questions about UMich? You can probably find answers here."
      >
        <div className={styles.contentWrapper}>
          {/* TOP LEVEL => show all top-level items */}
          {isTopLevel && (
            <div className={styles.mainMenu}>
              {menuItems?.map(item => {
                const linkProps = getLinkProps(item)
                return (
                  <MenuLink
                    key={item.id}
                    variant="main"
                    to={linkProps.to || ''}
                    external={linkProps.external || false}
                    url={item.url}
                  >
                    {item.label}
                  </MenuLink>
                )
              })}
            </div>
          )}

          {/* DEEPER LEVEL => show the active parent item + its children */}
          {!isTopLevel && activeItem && (
            <div className={styles.mainMenu}>
              {renderParentAndChildren(activeItem)}
            </div>
          )}
        </div>
      </Content>
    </>
  )
}
